import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions'; 
const firebaseConfig = {
  apiKey: "AIzaSyAD0la7UhyDsgcKhXpirjXDEN-Yl00ifFA",
  authDomain: "cartax-9932d.firebaseapp.com",
  projectId: "cartax-9932d",
  storageBucket: "cartax-9932d.appspot.com",
  messagingSenderId: "996856420064",
  appId: "1:996856420064:web:890590fb52c9fc9aad2285",
  measurementId: "G-DPDVMPGKR4"
};



export const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(firebaseApp);
export const auth = getAuth();

// Initialize Firebase Functions
const functions = getFunctions(firebaseApp); // Use getFunctions

export { functions }; // Export functions if needed