import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import OffersListScreen from './screens/TaxesListScreen'; 
import LoginScreen from './screens/LoginScreen';  
import SignupScreen from './screens/SignupScreen';  



const Stack = createStackNavigator();

const OffersNavigator = () => (
  <Stack.Navigator>
    <Stack.Screen name="Offers" component={OffersListScreen} />
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen name="Signup" component={SignupScreen} />
  </Stack.Navigator>
);


export default OffersNavigator;
