 import { Colors } from '../constants/styles';

export const style = {
  rootContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 16,
  },
  languagePicker: {
    marginBottom: '20px',
    alignSelf:"flex-end"
  },
  label: {
    fontSize: 12,
    color: Colors.gray,
    marginBottom: 4,
    textAlign: 'center',
  },
  picker: {
    color: Colors.gray,
    marginBottom: 10,
    height: 33,
    width:'70%',
    alignSelf: 'center',
    textAlign:'center'
  },
  contactLegalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'center',
    marginTop: 20, 
    width: '70%',
  },  

  contactLegalContainer2: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'center',
    marginTop: 20, 
    width: '40%',
  },  
  picker2: {
    color: Colors.gray,
    marginBottom: 10,
    height: 35,
    width:'80%',
    alignSelf: 'center',
    textAlign:'center',
    
  },
  dateLabel: {
    fontSize: 12,
    color: Colors.gray,
    marginBottom: 4,
    textAlign: 'right',
  },
  headerTitle: {
    fontSize: 18,
    color: Colors.gray,
    marginBottom: 16,
    textAlign:'center',
  },
  message: {
    fontSize: 18,
    color: Colors.gray,
    textAlign: 'center',
    marginBottom: 18,
  },

  textValue: {
    backgroundColor: "white",
    alignSelf: 'center',  
    padding: 20,
    
  },
  header: {
    width: '100%',
    alignItems: 'center',
  },
  body: {
    width: '60%',
  },

  body2: {
    width: '100%',
  },


  
  dropdownContainer: {
    flex: 1,  
    marginRight: 8, 
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,  
  },
  methodsPicker: {
    marginBottom: 12,
    borderColor: Colors.lightGray,
    zIndex : -5,
  },
  footer: {
    marginTop: 12,
    width: '100%',
    zIndex: -1,
  },
  result: {
    borderStyle: 'solid',
    borderColor: Colors.green,
    borderWidth: 1,
    borderRadius: 16,
    marginTop: 16,
    padding: 16,
    width: '100%',
    zIndex: -1,
  },
  resultTitle: {
    fontSize: 24,
    lineHeight: 34,
    textAlign: 'center',
  },
  resultTax: {
    fontSize: 24,
    lineHeight: 30,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  gridContainer: {
    display: "inline-block",
    position: "relative",
    width: "100%",
  },
  gridRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12, 

  },
  gridColumn: {
    flex: 1, 
    marginTop: 5
  },
  leftColumn: {
   float: "left",
    width: "48%",
  },
  leftColumn2: {
    width: "100%",
  },
  rightColumn: {
    float: "right",
    width: "48%",
  },
  rightColumn2: {
    float: "center",
    width: "100%",
  },
  resultContainer:{
    display: "flex",
    justifyContent:"space-around",
    flexDirection: 'row',
    width: '100%'

  },
  resultInput: {
    width: '80%'
  
  },

  input: {
    fontSize: 16,
    height: 30,
    padding: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.lightGray,
    backgroundColor: Colors.white,
    maxWidth:'300px',
    width:'70%',
    alignSelf: 'center',
    textAlign:'center'
  },
  input2: {
    fontSize: 16,
    height: 30,
    padding: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.lightGray,
    backgroundColor: Colors.white,
    maxWidth:'300px',
    width:'100%',
    alignSelf: 'center',
    textAlign:'center'
  },
  inputResult:{
    fontSize: 16,
    height: 26,
    padding: 5,
    borderColor: Colors.lightGray,
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: Colors.white,
    width:'70%',
    alignSelf: 'center',
    textAlign:'center',
    maxWidth:'300px',
  },
  inputResult2:{
    fontSize: 16,
    height: 26,
    padding: 5,
    borderColor: Colors.lightGray,
    marginBottom: 5,
    borderWidth: 1,
    color: "black",
    borderRadius: 8,
    backgroundColor: Colors.white,
    width:'70%',
    alignSelf: 'center',
    textAlign:'center',
    maxWidth:'300px',
  },

  
  contact: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: "30px"
  },
  contactText: {
    fontSize: 14,
    color: 'grey',
  },
  legal:{
    marginTop:'20px',

  },
  labelOfficialRate:{
    fontSize: 12,
    color: Colors.gray,
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
  }
  
}
