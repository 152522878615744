import { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAuth, signOut } from 'firebase/auth';
import { auth } from '../../constants/firebase-config';
import { Alert } from 'react-native';

export const AuthContext = createContext({
  token: '',
  uid: '',
  isAuthenticated: false,
  authenticate: async () => {},
  logout: async () => {}, 
});

export const userUidKey = 'userUid';
export const userTokenKey = 'userToken';

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState('');
  const [userUid, setUserUid] = useState('');
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    });
  
    return () => {
      // Cleanup logic to avoid memory leaks
      unsubscribe();
    };
  }, []);
  async function authenticate(uid, token) {
    setUserUid(uid);
    setAuthToken(token);
    try {
      if (uid && token) {
        await AsyncStorage.setItem(userUidKey, uid);
        await AsyncStorage.setItem(userTokenKey, token);
      }
    } catch (error) {
      console.warn('setItem', error);
    }
  }

  async function logout() {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUserUid('');
      setAuthToken('');
      await AsyncStorage.removeItem(userUidKey);
      await AsyncStorage.removeItem(userTokenKey);
    } catch (error) {
      console.error('Logout error:', error);
    }
  }
  

  const value = {
    token: authToken,
    uid: userUid,
    isAuthenticated: isAuth,
    authenticate,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
