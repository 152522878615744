import React, {useContext, useState, useEffect } from 'react';
import { Linking, Button } from 'react-native';
import 'firebase/auth';
import 'firebase/functions';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Loader from '../components/ui/Loader';
import { Colors } from '../constants/styles';
import {  Text, View, ScrollView, SafeAreaView, Pressable ,FlatList,ImageBackground,Image,TouchableOpacity } from 'react-native';
import { AuthContext } from '../store/context/auth-context';
import { useIsFocused } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import Modal from 'react-native-modal';
import { useNavigation } from '@react-navigation/native';
import { TextInput  } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import {Picker} from '@react-native-picker/picker';
import { styles } from '../constants/TaxesListScreenStyle';
import { v4 as uuidv4 } from 'uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'react-moment';
import moment, { lang } from 'moment';
// import { Lightbox } from 'react-lightbox-pack'; 
import Zoom from 'react-medium-image-zoom'
import "react-medium-image-zoom/dist/styles.css"
import { useMediaQuery } from 'react-responsive'
import { LanguageContext } from '../store/context/language-context';

function OffersListScreen() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('2023');
  const [dateCreation, setDateCreation] = useState('');
  const [kilometrage, setKilometrage] = useState(''); // New state for kilometrage
  const [carburant, setCarburant] = useState(''); // New state for carburant
  const [uid, setUid] = useState(''); // New state for carburant
  const [openTab, setOpenTab] = useState('CreateOffer'); // Default tab is CreateOffer
  const [isLoadingData, setIsLoadingData] = useState(false);
  const authContext = useContext(AuthContext);
  const [Offers, setOffers] = useState({ OffersList: [] });
  const [isLoadingOffersData, setIsLoadingOffersData] = useState(false);
  const isFocused = useIsFocused();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isCreateOfferModalVisible, setCreateOfferModalVisible] = useState(false);
  const [gearBoxList, setGearboxList] = useState([]);
  const [energyList, setEnergyList] = useState([]);
  const [selectedGearBox, setSelectedGearBox] = useState('Automatic');
  const [selectedEnergyList, setSelectedEnergyList] = useState();
  const userId = authContext.uid;

  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [selectedOfferForDeletion, setSelectedOfferForDeletion] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [editName, setEditName] = useState('');
  const [editPrice, setEditPrice] = useState('');
  const [editTel, setEditTel] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editDate, setEditDate] = useState('');
  const [editKilometrage, setEditKilometrage] = useState('');
  const [editCarburant, setEditCarburant] = useState('');
  const [editGearbox, setEditGearbox] = useState('');
  const [editImages, setEditImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  //const language = 'ar'
  // const { language, setlanguage } =  useContext(LanguageContext);
  // const [language, setLanguage] = useState('fr');
  const { language, setLanguage, getLanguage } = useContext(LanguageContext);

  const languageContext = useContext(LanguageContext);
  const [translations, setTranslations] = useState([]);

  const [selectedImages, setSelectedImages] = useState([]);
  
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1024px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })


  function OfferCard({ offer, onOfferPress, onDeleteOffer, onEditOffer }) {
    const handleEditButtonClick = () => {
      onEditOffer(offer);
    };
  
    return (
          <TouchableOpacity  onPress={() => onOfferPress(offer)}>
                

        <View style={styles.offerCard}>
        <Text style={styles.offerTitle}>{offer.name}</Text>     
           <View style={styles.offerCard2}>
                <View style={styles.imageContainer}>
              <Image source={{ uri: offer.imageUrls[0] }} style={styles.offerImage} />
                 </View>
                <View style={styles.offerText}>

                  <View style={styles.offerDetails}>
                  
                    <Text style={styles.offerDate}>{translations.dateCreation} {moment(new Date(offer.dateCreation._seconds * 1000)).format('DD/MM/YYYY')} </Text>
                  
                    <View style={styles.offerLines}>
                        <View style={styles.offercolumns}>
                            <Text style={styles.offerPrice}>
                              <Ionicons name="md-pricetag" size={20} color="black" /> {offer.price} €
                            </Text>
                            <Text style={styles.offerPrice}>
                            <Ionicons name="md-car-sport" size={20} color="black" /> {offer.kilometrage} KM
                          </Text>
                          <Text style={styles.offerDate}>
                            <Ionicons name="md-calendar" size={20} color="black" /> {offer.date}
                          </Text>
                        </View>

                        <View style={styles.offercolumns}>

                        <Text style={styles.offerPrice}>
                          <Ionicons name="md-flame" size={20} color="black" /> {energyList[offer.carburant].label}
                        </Text>
                        <Text style={styles.offerPrice}>
                                  <Ionicons name="md-settings" size={20} color="black" /> {gearBoxList[offer.gearbox].label}
                                </Text>
                        </View>

                    </View>
                  </View>

                  {authContext.isAuthenticated ? (
                    <View style={styles.offerButtons}>
                      {userId === offer.uid && (
                        <>
                          <TouchableOpacity style={styles.editButton} onPress={() => openEditModal(offer)}>
                            <Text style={styles.editButtonText}>{translations.edit}</Text>
                          </TouchableOpacity>
                          <TouchableOpacity style={styles.deleteButton} onPress={() => onDeleteOffer(offer)}>
                            <Text style={styles.deleteButtonText}>{translations.delete}</Text>
                          </TouchableOpacity>
                        </>
                      )}
                    </View>
                  ) : (
                    <View style={styles.headerButtonContainer}>
                      {/* Render additional non-authenticated content if needed */}
                    </View>
                  )}
                  
                </View>
           </View>
       </View>

          </TouchableOpacity>
    );
  }
  
    // Function to calculate the total number of pages
    const totalPages = Math.ceil(Offers.OffersList.length / itemsPerPage);


    const sortOffersByDateCreation = (offers) => {
      return offers.sort((a, b) => {
        return b.dateCreation._seconds * 1000 - a.dateCreation._seconds * 1000; // Sorting in descending order
      });
    };

    // Function to display offers for the current page
    const displayOffersForCurrentPage = () => {
      const sortedOffers = sortOffersByDateCreation(Offers.OffersList);

      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return sortedOffers.slice(startIndex, endIndex);
    };
  

    const navigation = useNavigation();

    // Function to handle page change
    const handlePageChange = (newPage) => {
      setCurrentPage((prevPage) => (prevPage !== newPage ? newPage : prevPage));
    };
    
      const importOptionsByLanguage = async () => {

        //console.log(language)
        switch (language) {
          case 'en':
            return import('../constants/en');
          case 'ar':
            return import('../constants/ar');
          default:
            return import('../constants/fr'); 
        }
      };  
  
  const translate = (key) => translations[key];

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection: true, // Allow multiple image selection
      aspect: [4, 3],
      quality: 1,
    });
  
    if (!result.canceled) {
      const selectedImageURIs = result.assets.map((asset) => asset.uri);
      // Limit the number of selected images to 5
      if (editImages.length + selectedImageURIs.length <= 6) {
        setEditImages((prevImages) => [...prevImages, ...selectedImageURIs]);
      } else {
        // //console.log('You can only select up to 6 images.');
      }

      if (selectedImages.length + selectedImageURIs.length <= 6) {
        setSelectedImages((prevImages) => [...prevImages, ...selectedImageURIs]);
      } else {
        // //console.log('You can only select up to 6 images.');
      }
    }
  };
  
  const removeImage = (index) => {
    setEditImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };
  
 
  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(false);
  };

  const handleDeleteOffer = (offer) => {
    setSelectedOfferForDeletion(offer); // Set the selected offer to delete
    openDeleteConfirmationModal(); // Open the confirmation modal
  };
  
  const openDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(true);
  };


  useEffect(() => {

    const initializeLanguage = async () => {
      await getLanguage().then((storedLanguage) => {
        if (storedLanguage) {
          setLanguage(storedLanguage);
        }
      });
    };

    initializeLanguage();

    importOptionsByLanguage().then((options) => {
     setGearboxList(options.gearBoxList);
     setEnergyList(options.energyList);
     setTranslations(options.translations);
    });


    getOffersList();
  }, [language]);


  const openModal = (offer) => {
    setSelectedOffer(offer);
    setModalVisible(true);
  };


  const openCreateOfferModal = () => {
    setCreateOfferModalVisible(true);
  };

  const closeCreateOfferModal = () => {
    setCreateOfferModalVisible(false);
      setSelectedImages([]);
 
  };

  const openEditModal = (offer) => {

  setSelectedOffer(offer);
  setEditName(offer.name);
  setEditPrice(offer.price);
  setEditTel(offer.tel);
  setEditEmail(offer.email);
  setEditDescription(offer.description);
  setEditDate(offer.date);
  setEditKilometrage(offer.kilometrage || ''); // Initialize with an empty string
  setEditCarburant(offer.carburant || ''); // Initialize with an empty string
  setEditGearbox(offer.gearbox || '');
  setEditImages(offer.imageUrls || []);
  setEditModalVisible(true);
  
};

const closeEditModal = () => {
  setEditModalVisible(false);
};

  const goToLoginScreen = () => {
    navigation.navigate('Login'); 
  };

  const getOffersList = async () => {
    const functions = getFunctions();
    const getCarsFunction = await httpsCallable(functions, 'getCarOffers');

    const offerData = {
      name,
      email,
      price,
      tel,
      description,
      date,
      dateCreation,
      kilometrage,  
      selectedGearBox,    
      carburant, 
      uid,
    };

   
    setIsLoadingData(true);

    try {
      const result = await getCarsFunction({ data: offerData });

      if (Array.isArray(result.data.offersData)) {
    //  const formattedDate = moment(result.data.offersData[2].dateCreation).format('DD/MM/YYYY');

      setOffers({ OffersList: result.data.offersData });
      } else {
        setOffers({ OffersList: [] });
      }
    } catch (error) {
      // //console.log('Error', error);
    } finally {
      setIsLoadingData(false);
    }
  };


  const getLanguageSaved = async (newlanguage) => {
   await getLanguage().then((value) => {
    //console.log('new', value)
   });
  
    };

  const handleLanguageChange = async (newlanguage) => {
    await setLanguage(newlanguage); 
  };


  const createOffer = async () => {
    const offerId = uuidv4();
  
    const functions = getFunctions();
    const { data: resultData } = await httpsCallable(functions, 'uploadImages')({ imageURIs: selectedImages, offerId });
  
    if (resultData?.success) {
      const imageUrls = resultData.imageUrls;
  
      const offerData = {
        name,
        email,
        tel,
        price,
        description,
        date,
        kilometrage,
        gearbox: selectedGearBox,
        carburant: selectedEnergyList,
        imageUrls,
        id: offerId,
      };
  
      try {
        setIsLoadingData(true);
        closeCreateOfferModal();
  
        const response = await httpsCallable(functions, 'createCarOffer')(offerData);
  
        if (response.data?.success) {
          // //console.log('Offer created successfully.');
          getOffersList();
        } else {
          // console.error('Error creating car offer:', response.data?.message);
        }
      } catch (error) {
        // console.error('Error creating car offer:', error);
      } finally {
        setIsLoadingData(false);
      }
    } else {
      // //console.log('Error uploading images.');
    }
  };
  
  const editOffer = async (selectedOffer) => {
    const functions = getFunctions();
    const editCarOfferFunction = await httpsCallable(functions, 'editCarOffer');

    const offerData = {
      id: selectedOffer.id, 
      name: editName,
      price: editPrice,
      price: editPrice,
      email: editEmail,
      description: editDescription,
      date: editDate,
      kilometrage: editKilometrage,
      gearbox: editGearbox,
      carburant: editCarburant,
      imageUrls: editImages,
      // Add other fields here    
    };
  
    setIsLoadingData(true);
    closeEditModal();
    try {

      const response = await editCarOfferFunction(offerData);


      if (response.data && response.data.success) {
        // //console.log('Offer updated successfully.');
        getOffersList();

      } else {
        // console.error('Error updating car offer:', response.data.message);
      }
    } catch (error) {
      // console.error('Error updating car offer:', error);
    } finally {
      setIsLoadingData(false);
    }
  };
  

  const handleConfirmDelete = async () => {
    if (selectedOfferForDeletion) {
      // Call the deleteCarOfferFunction to delete the selected offer
      const functions = getFunctions();
      const deleteCarOfferFunction = httpsCallable(functions, 'deleteCarOffer');
      closeDeleteConfirmationModal();
  
      try {
        const response = await deleteCarOfferFunction({ id: selectedOfferForDeletion.id });
  
        if (response.data?.success) {
          // Offer deleted successfully, refresh the list
          getOffersList();
        } else {
          console.error('Error deleting car offer:', response.data?.message);
        }
      } catch (error) {
        console.error('Error deleting car offer:', error);
      }
  
      // Reset selectedOfferForDeletion and refresh the list
      setSelectedOfferForDeletion(null);
      getOffersList();
    }
  };
  
  const handlePhoneCall = (phoneNumber) => {
    const phoneUrl = `tel:${phoneNumber}`;
    Linking.canOpenURL(phoneUrl)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(phoneUrl);
        } else {
          console.warn("Phone number can't be opened");
        }
      })
      .catch((error) => console.error('Error opening phone number:', error));
  };

  const styleTag = document.createElement('style');
  styleTag.appendChild(document.createTextNode(styles));
  document.head.appendChild(styleTag);

  return (
    <ScrollView>
    <SafeAreaView style={styles.rootContainer}>

      <View style={styles.header}> 
          <Picker
             selectedValue={language}
            onValueChange={handleLanguageChange}  
            style={styles.languagePicker}
          >
            <Picker.Item label="English" value="en" />
            <Picker.Item label="Français" value="fr" />
            <Picker.Item label="عربي" value="ar" />
          </Picker>
        </View>

        {!authContext.isAuthenticated ? (
      <View style={styles.headerButtonContainer}>
        <TouchableOpacity
          style={styles.editButton}
          onPress={goToLoginScreen}>
          <Text style={styles.editButtonText}>{translations.createOffer}</Text>
        </TouchableOpacity>
      </View>
    ) : (
      <View style={styles.headerButtonContainer}>
        <TouchableOpacity
          style={styles.editButton}
          onPress={() => openCreateOfferModal()}>
          <Text style={styles.editButtonText}>{translations.createOffer}</Text>
        </TouchableOpacity>
      </View>
    )}

      {isLoadingData ? (
        <Loader />
      ) : (

        <ScrollView style={styles.offerList}>
        {/* {Offers.OffersList.map((offer) => ( */}
        {displayOffersForCurrentPage().map((offer) => (
          <OfferCard
            key={offer.id}
            offer={offer}
            onOfferPress={openModal}
            onDeleteOffer={handleDeleteOffer}
            onEditOffer={editOffer} 
          />
        ))}
      </ScrollView>

      )}
       {/* Modal to display the selected offer information */}
       <Modal isVisible={isModalVisible  && language !== "ar"} onBackdropPress={() => setModalVisible(false)}>
            <View style={styles.descriptionModalContent}>
              {selectedOffer && (
                <>

                  <View styles={styles.offerDetails} >
                                    {/* <Text style={styles.label}> {translations.email} {selectedOffer.email}</Text> */}
                                    <Text style={styles.offerDate}>
                                      <Ionicons name="md-mail" size={20} color="black" /> {translations.email} : {selectedOffer.email}
                                    </Text>
           
                                  <Text style={styles.offerDate}>
                                  <Ionicons name="md-call" size={20} color="black" /> {translations.tel} : 
                                    <TouchableOpacity onPress={() => handlePhoneCall(selectedOffer.tel)}>
                                      {selectedOffer.tel}
                                    </TouchableOpacity>
                                  </Text>
                                    <Text style={styles.offerDate}>
                                      <Ionicons name="md-pricetag" size={20} color="black" /> {translations.price} : {selectedOffer.price} €
                                    </Text>
                                    <Text style={styles.offerDate}>
                                      <Ionicons name="md-car-sport" size={20} color="black" /> {translations.kilometrage} : {selectedOffer.kilometrage} KM
                                    </Text>
                                    <Text style={styles.offerDate}>
                                      <Ionicons name="md-calendar" size={20} color="black" /> {translations.date} : {selectedOffer.date}
                                    </Text>
                                    <Text style={styles.offerDate}>
                                    <Ionicons name="md-settings" size={20} color="black" /> {translations.gearbox} :  {gearBoxList[selectedOffer.gearbox].label}
                                  </Text>
                                  <Text style={styles.offerDate}>
                                    <Ionicons name="md-flame" size={20} color="black" />{translations.carburant} :   {energyList[selectedOffer.carburant].label}
                                  </Text>
                                    <Text style={styles.offerDate}>
                                      <Ionicons name="md-information-circle" size={20} color="black" /> {translations.description} : {selectedOffer.description}
                                    </Text>

                  </View>
   
                  <View style={{ marginTop: 10 }}>
                      {selectedOffer.imageUrls && selectedOffer.imageUrls.length > 0 ? (
                       
                        <FlatList 
                          data={selectedOffer.imageUrls}
                          keyExtractor={(item, index) => index.toString()}
                          numColumns={isTabletOrMobile ? 2 : 3} 
                          renderItem={({ item }) => (
                            <Zoom >
                            <Image  source={{ uri: item }} style={{ width: '130px', height: '100px',  margin: 5 }} />
                            </Zoom>
                          )}
                        />
                      
                      ) : (
                        <Text>{translations.noImages}</Text>
                      )}
              </View> 
                </>
              )}
            </View>
        </Modal>



        <Modal isVisible={isModalVisible && language === "ar"} onBackdropPress={() => setModalVisible(false)}>
            <View style={styles.descriptionModalContent}>
              {selectedOffer && (
                <>


                  <View styles={styles.offerDetails} >
                  <Pressable style={styles.closeButton} onPress={closeEditModal}>
        {/* Close icon (you can replace this with your own close icon) */}
        <Text style={styles.closeIcon}>X</Text>
      </Pressable>
                   {/* <Text style={styles.label}> {translations.email} {selectedOffer.email}</Text> */}
                   <Text dir="rtl" style={styles.offerDate}>
                    <Ionicons name="md-mail" size={20} color="black" /> {translations.email} : {selectedOffer.email}
                  </Text>
                  <Text dir="rtl" style={styles.offerDate}>
                    <Ionicons name="md-call" size={20} color="black" /> {translations.tel} : {selectedOffer.tel}
                  </Text>
                  <Text dir="rtl" style={styles.offerDate}>
                    <Ionicons name="md-pricetag" size={20} color="black" /> {translations.price} : € {selectedOffer.price}
                  </Text>
                  <Text dir="rtl" style={styles.offerDate}>
                    <Ionicons name="md-car-sport" size={20} color="black" />  {translations.kilometrage} (KM): {selectedOffer.kilometrage} 
                  </Text>
                  <Text dir="rtl" style={styles.offerDate}>
                    <Ionicons name="md-calendar" size={20} color="black" /> {translations.date} : {selectedOffer.date}
                  </Text>
                  <Text dir="rtl" style={styles.offerDate}>
                  <Ionicons name="md-settings" size={20} color="black" /> {translations.gearbox} :  {gearBoxList[selectedOffer.gearbox].label}
                  </Text>
                  <Text dir="rtl"  style={styles.offerDate}>
                    <Ionicons name="md-flame" size={20} color="black" />{translations.carburant} :   {energyList[selectedOffer.carburant].label}
                  </Text>
                  <Text dir="rtl"  style={styles.offerDate}>
                    <Ionicons name="md-information-circle" size={20} color="black" /> {translations.description} : {selectedOffer.description}
                  </Text>

                  </View>


                  <View style={{ marginTop: 10 }}>
                      {selectedOffer.imageUrls && selectedOffer.imageUrls.length > 0 ? (
                       
                        <FlatList 
                          data={selectedOffer.imageUrls}
                          keyExtractor={(item, index) => index.toString()}
                          numColumns={isTabletOrMobile ? 2 : 3} 
                          renderItem={({ item }) => (
                            <Zoom >
                            <Image  source={{ uri: item }} style={{ width: '130px', height: '100px',  margin: 5 }} />
                            </Zoom>
                          )}
                        />
                      
                      ) : (
                        <Text>{translations.noImages}</Text>
                      )}
              </View> 
                </>
              )}
            </View>
        </Modal>

   <Modal isVisible={isCreateOfferModalVisible} style={styles.modalCreateOffer} onBackdropPress={() => closeCreateOfferModal(false)} 
            >

              
        <View style={styles.modalContent}>

          <View >

            <Text style={styles.label}>{translations.offerTitle}</Text>
            <TextInput
              style={styles.textInput}
              placeholder=""
              value={name}
              maxLength={30}
              onChangeText={(text) => setName(text)}
            />
          </View>
          <View >
            <Text style={styles.label}>{translations.tel}</Text>
            <TextInput
              style={styles.textInput}
              placeholder=""
              keyboardType="numeric"
              value={tel}
              onChangeText={(text) => {
                // Filter out non-numeric characters
                const numericText = text.replace(/[^0-9]/g, '');
                setTel(numericText);
              }}
            />
          </View>

          <View >
            <Text style={styles.label}>{translations.email}</Text>
            <TextInput
              style={styles.textInput}
              placeholder=""
              keyboardType="email-address"
              autoCapitalize="none"
              value={email}
              onChangeText={(text) => setEmail(text)}
            />
          </View>

          <View >
            <Text style={styles.label}>{translations.description}</Text>
            <TextInput
              style={styles.textInput}
              placeholder=""
              value={description}
              onChangeText={(text) => setDescription(text)}
              multiline={true} /* Enable multiline */
              numberOfLines={4}
              maxLength={500}
            />
          </View>

      <View style={styles.dropInputsRow}>
               <View style={styles.dropInputsRowView} >
                    <Text style={styles.rowLabel}>{translations.price}</Text>
                    <TextInput style={styles.rowInput} placeholder="" keyboardType="numeric"  value={price}  onChangeText={(text) => {const numericText = text.replace(/[^0-9]/g, ''); setPrice(numericText);}}/>
               </View>

               <View style={styles.dropInputsRowView} >
                      <Text style={styles.rowLabel}>{translations.kilometrage} </Text>
                      <TextInput  style={styles.rowInput}  placeholder="" value={kilometrage}  keyboardType="numeric"   maxLength={6} onChangeText={(text) => {const numericText = text.replace(/[^0-9]/g, ''); setKilometrage(numericText);}}/>
                </View>
      </View>





              <View >
                    <Text style={styles.label}>{translations.date}</Text>
                    <Picker
                        style={styles.textInput}
                        selectedValue={date}
                        onValueChange={(itemValue, itemIndex) => setDate(itemValue)}
                      >
                        {Array.from({ length: 14 }, (_, index) => {
                          const year = 2010 + index;
                          return (
                            <Picker.Item key={year} label={year.toString()} value={year.toString()} />
                          );
                        })}
                      </Picker>
                </View>
              

                <View style={styles.dropListsRow}>

                <View style={styles.dropListCreateModal}>
                                    <Text style={styles.label}>{translations.gearbox}</Text>
                                <Picker style={styles.picker}
                                    selectedValue={selectedGearBox}
                                    onValueChange={(itemValue, itemIndex) => {
                                      setSelectedGearBox(itemValue);
                                    }}
                                  >
                                    <Picker.Item label="" value="" />
                                    {gearBoxList.map((item, index) => (
                                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                                    ))}
                                  </Picker>

                </View>
                <View style={styles.dropListCreateModal}>
                                <Text style={styles.label}>{translations.carburant}</Text>
                          <Picker style={styles.picker}
                                        selectedValue={selectedEnergyList}
                                        // onChangeText={(text) => setSelectedEnergyList(text)}
                                        onValueChange={(itemValue, itemIndex) => {
                                          setSelectedEnergyList(itemValue);
                                        }}
                                      >
                                        <Picker.Item label="" value="" />
                                        {energyList.map((item, index) => (
                                          <Picker.Item key={item.value} label={item.label} value={item.value} />
                                        ))}
                                      </Picker>
                </View>


                </View>

                <Pressable style={styles.addImagesButton} onPress={pickImage}>
                  <Text style={styles.buttonText}>{translations.addImages}</Text>
                </Pressable>

              
                    <View style={styles.selectedImagesContainer}>
                      <FlatList
                        data={selectedImages}
                        numColumns={2}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item, index }) => (
                          <View style={styles.selectedImageContainer}>
                            <Image key={index} source={{ uri: item }} style={styles.selectedImage} />
                            <Pressable
                              style={styles.removeImageButton}
                              onPress={() => removeImage(index)}
                            >
                              <Text style={styles.buttonText}>{translations.delete}</Text>
                            </Pressable>
                            
                          </View>
                        )}
                      />
      </View>



                <View style={styles.buttonContainer}>
                  <Pressable  style={[
          styles.createButton,
          {
            backgroundColor: !name || !price || !tel || !email ||  !description ||  !kilometrage ||  !selectedGearBox  ||  !selectedEnergyList  ? 'lightgreen' : 'green', // Change the background color accordingly
          },
        ]}
                  disabled={!name || !price || !tel || !email || !description ||  !kilometrage ||  !selectedGearBox  ||  !selectedEnergyList  }
                  onPress={createOffer}>
                    <Text style={styles.buttonText}>{translations.create}</Text>
                  </Pressable>
                  <Pressable style={styles.cancelButton} onPress={closeCreateOfferModal}>
                    <Text style={styles.buttonText}>{translations.cancel}</Text>
                  </Pressable>
                </View>
              </View>
      </Modal>

    <Modal isVisible={isEditModalVisible}  style={styles.modalCreateOffer}  onBackdropPress={() => closeEditModal}>
    <ScrollView>
    <View style={styles.modalContent}>
    <Pressable style={styles.closeButton} onPress={closeEditModal}>
            {/* Close icon (you can replace this with your own close icon) */}
            <Text style={styles.closeIcon}>X</Text>
          </Pressable>
        <Text style={styles.label}>{translations.editCarOffer}</Text>



        <View>
        <Text style={styles.label}>{translations.offerTitle}</Text>
        <TextInput
          style={styles.textInput}
          placeholder=""
          value={editName}
          onChangeText={(text) => setEditName(text)}
        />
          </View>

          <View >
          <Text style={styles.label}>{translations.email}</Text>
            <TextInput
              style={styles.textInput}
              placeholder=""
              value={editEmail}
              onChangeText={(text) => setEditEmail(text)}
            />
          </View>


          <View >
          <Text style={styles.label}>{translations.tel}</Text>
            <TextInput
              style={styles.textInput}
              placeholder=""
              value={editTel}
              onChangeText={(text) => {
                // Filter out non-numeric characters
                const numericText = text.replace(/[^0-9]/g, '');
                setEditTel(numericText);
              }}
            />
          </View>


            <View >
          <Text style={styles.label}>{translations.description}</Text>
        <TextInput
          style={styles.textInput}
          placeholder=""
          value={editDescription}
          onChangeText={(text) => setEditDescription(text)}
          multiline={true} /* Enable multiline */
          numberOfLines={4}
          maxLength={500}
        />
          </View>



          <View style={styles.dropInputsRow}>
                  <View style={styles.dropInputsRowView} >
                          <Text style={styles.rowLabel}>{translations.price}</Text>
                    <TextInput style={styles.rowInput} placeholder="" value={editPrice}  keyboardType="numeric" onChangeText={(text) => {const numericText = text.replace(/[^0-9]/g, ''); setEditPrice(numericText);}}/>
                  </View>

              <View  style={styles.dropInputsRowView}>
                    <Text style={styles.rowLabel}>{translations.kilometrage}</Text>
                    <TextInput  style={styles.rowInput}  placeholder="" maxLength={6} keyboardType="numeric" value={editKilometrage} onChangeText={(text) => {const numericText = text.replace(/[^0-9]/g, ''); setEditKilometrage(numericText);}} />
                  </View>
          </View>




          <View >
          <Text style={styles.label}> {translations.date}</Text>

          <Picker
            style={styles.textInput}
            selectedValue={editDate}
            onValueChange={(itemValue, itemIndex) => setEditDate(itemValue)}
          >
            {Array.from({ length: 14 }, (_, index) => {
              const year = 2010 + index;
              return (
                <Picker.Item key={year} label={year.toString()} value={year.toString()} />
              );
            })}
          </Picker>

          </View>


          <View style={styles.dropListsRow}>

              <View style={styles.dropListCreateModal}>
              <Text style={styles.label}>{translations.gearbox}</Text>
                  <Picker
                    style={styles.picker}
                    selectedValue={editGearbox}
                    onValueChange={(itemValue, itemIndex) => setEditGearbox(itemValue)}
                  >
                    {gearBoxList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>


              </View>
              <View style={styles.dropListCreateModal}>
              <Text style={styles.label}>{translations.carburant}</Text>
                      <Picker
                    style={styles.picker}
                    selectedValue={editCarburant}
                    onValueChange={(itemValue, itemIndex) => setEditCarburant(itemValue)}
                  >
                    {energyList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>

              </View>


    </View>



        <Pressable style={styles.addImagesButton} onPress={pickImage}>
          <Text style={styles.buttonText}>{translations.addImages}</Text>
        </Pressable>
        {editImages.length > 0 && (
          <View style={styles.selectedImagesContainer}>
            <FlatList
              data={editImages}
              numColumns={2}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <View style={styles.selectedImageContainer}>
                  <Image source={{ uri: item }} style={styles.selectedImage} />
                  <Pressable
                    style={styles.removeImageButton}
                    onPress={() => removeImage(index)}
                  >
                    <Text style={styles.buttonText}>{translations.delete}</Text>
                  </Pressable>
                </View>
              )}
            />
          </View>
        )}
        <View style={styles.buttonContainer}>

                 

          <Pressable style={[styles.saveButton,    { backgroundColor: !editName || !editPrice || !editTel || !editEmail ||  !editDescription ||  !editKilometrage ||  !editGearbox  ||  !editCarburant  ? 'lightblue' : 'blue' }]  }
            onPress={() => editOffer(selectedOffer)}
            disabled={!editName || !editPrice || !editTel || !editEmail || !editDescription ||  !editKilometrage ||  !editGearbox  ||  !editCarburant  }
            >
            <Text style={styles.buttonText}>{translations.saveChanges}</Text>
          </Pressable>
          <Pressable style={styles.cancelButton} onPress={closeEditModal}>
            <Text style={styles.buttonText}>{translations.cancel}</Text>
          </Pressable>
        </View>




    </View>

      </ScrollView>
    </Modal>

    <Modal isVisible={isDeleteConfirmationVisible} style={styles.deleteModal} onBackdropPress={closeDeleteConfirmationModal}>
      <View style={styles.modalContent}>
        <Text>{translations.areYouSureDelete}</Text>
        <View style={styles.buttonContainer}>
          <Pressable style={styles.confirmButton} onPress={handleConfirmDelete}>
            <Text style={styles.buttonText}>{translations.confirm}</Text>
          </Pressable>
          <Pressable style={styles.cancelButton} onPress={closeDeleteConfirmationModal}>
            <Text style={styles.buttonText}>{translations.cancel}</Text>
          </Pressable>
        </View>
      </View>
    </Modal>



    {/* {isLoadingData ? (
        <Loader />
      ) : ( */}
        {totalPages > 1 && (
          <View style={styles.paginationContainer}>
            {[...Array(totalPages).keys()].map((page) => (
              <TouchableOpacity
                key={page + 1}
                onPress={() => handlePageChange(page + 1)}
                style={[
                  styles.paginationButton,
                  currentPage === page + 1 && styles.activePaginationButton,
                ]}
              >
                <Text style={styles.paginationButtonText}>{page + 1}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
      
    </SafeAreaView>
    </ScrollView>
  );


  
  
}

export default OffersListScreen;

 
 