import React from 'react';
import { View, Text, StyleSheet,ScrollView } from 'react-native';

const LegalMentionsScreen = () => {
  return (
    <ScrollView style={styles.container}>
      <Text style={styles.legalText}>
        <Text style={styles.heading}>Propriété et Responsable de la publication :</Text>
        {'\n\n'}
        <Text>Dztaxe</Text>
        {'\n\n'}
        <Text style={styles.heading}>Raison Sociale de l’Hébergeur :</Text>
        {'\n\n'}
        <Text>Ce site est hébergé par Firebase, Google.</Text>
        {'\n\n'}
        <Text>Pour contacter cet hébergeur, rendez-vous à l'adresse</Text>
        {'\n\n'}
        <Text style={styles.link}>https://www.firebase.google.com</Text>
        {'\n\n'}
        <Text style={styles.heading}>Propriété intellectuelle :</Text>
        {'\n\n'}
        <Text>La structure générale ainsi que les logiciels, textes, images animées ou non, son savoir-faire et tous les autres éléments composant le site sont la propriété exclusive de Dztaxe.</Text>
        {'\n\n'}
        <Text>Les logos et marques des sociétés citées sur le site sont la propriété exclusive de leurs auteurs respectifs. Toute représentation, reproduction et/ou exploitation, qu'elle soit partielle ou totale, des marques précitées est interdite, sauf accord écrit et préalable de leurs titulaires.</Text>
        {'\n\n'}
        <Text style={styles.heading}>Droits d’auteur :</Text>
        {'\n\n'}
        <Text>Toute représentation totale ou partielle de ce site par quelle que personne que ce soit, sans l’autorisation expresse de Dztaxe, est interdite et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.</Text>
        {'\n\n'}
        <Text>L’élaboration de liens hypertextes profonds vers le site est interdite sans l’accord exprès et préalable de l’éditeur.</Text>
        {'\n\n'}
        <Text style={styles.heading}>Avertissement général :</Text>
        {'\n\n'}
        <Text>La consultation du site est proposée aux internautes à titre gratuit et sans aucune garantie de la part de son éditeur. Les informations disponibles sur le site qui proviennent de sources extérieures ne sauraient garantir qu’elles sont exemptes d’erreurs, ni garantir leur complétude, leur actualité, leur exhaustivité ou autre.</Text>
        {'\n\n'}
        <Text>En conséquence, il appartient à l’internaute d’utiliser les informations figurant sur le site Internet à ses risques et de procéder à toute vérification sous sa responsabilité exclusive. Dztaxe ne serait être tenu responsable de l’interprétation faite par les internautes, quelle qu’elle soit, de tous les renseignements, informations et conseils proposés sur son site, et des conséquences pouvant en découler.</Text>
        {'\n\n'}
        <Text style={styles.heading}>Les propriétaires des sites marchands, de service, sites persos, sites informatiques (etc.) :</Text>
        {'\n\n'}
        <Text>Consultés à partir du site sont seuls responsables du respect par eux de l’ensemble des réglementations s’appliquant dans le cadre des prestations offertes aux clients finaux, et notamment, des lois et règlements relatifs à :</Text>
        {'\n\n'}
        <Text>- La vente à distance,</Text>
        <Text>- La protection du consommateur,</Text>
        <Text>- La publicité mensongère ou trompeuse,</Text>
        <Text>- Les prix,</Text>
        <Text>- La conformité des produits.</Text>
        {'\n\n'}
        <Text>Toutes les marques citées sont déposées par leurs propriétaires respectifs.</Text>
        {'\n\n'}
        <Text style={styles.heading}>Utilisation de Cookies :</Text>
        {'\n\n'}
        <Text>Dztaxe vous informe qu'un ou plusieurs cookies (petits fichiers textes) peuvent s'installer automatiquement sur le disque dur de votre ordinateur lors de votre visite sur le site.</Text>
        {'\n\n'}
        <Text>Ces cookies peuvent provenir des partenaires de Dztaxe, mais aussi des systèmes de statistiques du site ou de tous autres services proposés sur le site. Vous avez la possibilité de supprimer les cookies installés lors de votre visite sur le site, à l’aide de la barre d'outils de votre navigateur.</Text>
        {'\n\n'}
        <Text>La suppression des cookies devra intervenir une fois votre navigation sur le site terminée. À défaut, vous êtes informé du fait que l’usage du site, ainsi que les services qui y sont proposés, pourront être perturbés, voire empêchés.</Text>
        {'\n\n'}
        <Text style={styles.heading}>Crédits et Copyright Photos :</Text>
        {'\n\n'}
        <Text>Les crédits et copyright des visuels et des photos présents sur le site sont signalés dans les articles lorsque elles proviennent d'un professionnel avec son accord.</Text>
        {'\n\n'}
        <Text style={styles.heading}>Annonces de Vente de Voitures :</Text>
        {'\n\n'}
        <Text>Les utilisateurs sont seuls responsables du contenu de leurs annonces de vente de voitures. Dztaxe se désengage de toute responsabilité quant au contenu, à l'exactitude, et à la légalité de ces annonces.</Text>
        {'\n\n'}
      </Text>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  legalText: {
    fontSize: 16,
  },
  heading: {
    fontWeight: 'bold',
  },
  link: {
    color: 'blue', 
  },
});

export default LegalMentionsScreen;
