import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const LanguageContext = createContext({
  language: 'ar',
  setLanguage: async () => {},
  getLanguage: async () => {},
});

function LanguageContextProvider({ children }) {
  const [language, setLanguage] = useState('ar');

  useEffect(() => {
    const initializeLanguage = async () => {
      try {
        const storedLanguage = await AsyncStorage.getItem('userLanguage');
        if (storedLanguage) {
          setLanguage(storedLanguage);
        }
      } catch (error) {
        console.warn('Error fetching language:', error);
      }
    };

    initializeLanguage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run this effect only on mount

  async function setLanguageFunc(newLanguage) {
    try {
      if (newLanguage) {
        //console.log('context set', newLanguage);
        await AsyncStorage.setItem('userLanguage', newLanguage);
        setLanguage(newLanguage);
      }
    } catch (error) {
      console.warn('setItem', error);
    }
  }

  const getLanguageFunc = async () => {
    try {
      const storedLanguage = await AsyncStorage.getItem('userLanguage');
      return storedLanguage || 'ar'; // Return a default language ('ar') if none is found
    } catch (error) {
      console.warn('getItem', error);
      return 'ar'; // Return a default language ('ar') if there's an error
    }
  };

  const value = {
    language,
    setLanguage: setLanguageFunc,
    getLanguage: getLanguageFunc,
  };

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
}

export default LanguageContextProvider;
