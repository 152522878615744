 import { Colors } from '../constants/styles';

export const styles = {
  rootContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 26,
  },
  heading: {
    fontSize: 28,
    color: Colors.gray,
    marginBottom: 16,
  },
  list: {
    flexDirection: 'row',
    display: 'flex',
    paddingLeft: 24,
    paddingRight: 24,
    flex: 1, 
  },
  card: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    marginBottom: 12,
    width: '100%',
    backgroundColor: Colors.white,
    borderRadius: 16,
  },
  leftSide: { flex: 1 },
  rightSide: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  date: {
    flexDirection: 'column',
    color: Colors.mediumGray,
    marginBottom: 16,
  },
  day: { fontSize: 14 },
  month: { fontSize: 14 },
  year: {
    fontSize: 14,
    color: Colors.mediumGray,
  },
  OfferAmount: {
    color: Colors.green,
    fontSize: 18,
    marginBottom: 4,
  },
  taxAmountInLari: {
    color: Colors.gray,
    fontSize: 18,
  },
  removeButton: {
    padding: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.lightGray,
  },
  pressed: {
    borderColor: Colors.error700,
  },
  emptyRecords: {
    marginTop: 32,
    textAlign: 'center',
    fontSize: 20,
    color: Colors.mediumGray,
  },
  totalTax: {
    textAlign: 'center',
    fontSize: 16,
    color: Colors.mediumGray,
    paddingTop: 16,
    paddingBottom: 32,
  },
  headerButtonContainer: {
    // position: 'absolute',
    // top: 10,  
    // right: 10, 
    width: 'auto',  
    height: 40, 
    padding: 5,
    marginRight: 10, 
    // marginBottom: '20px',
    alignSelf:"flex-end"
  },

  shareButton: {
    backgroundColor: '#2ecc71',
    padding: 8,
    borderRadius: 4,
  },
  shareButtonText: {
    color: '#fff',
  },
  headerButton: {
    backgroundColor: Colors.primary, // Change to your desired background color
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  headerButtonText: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    // maxWidth:"700px",
    alignSelf: 'center',
   width: "50%",
   minWidth: "350px"
  },

  // modalContent: {
  //   width: '80%',
  //   backgroundColor: 'white',
  //   padding: 20,
  //   borderRadius: 10,
  // },

  descriptionModalContent:{
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignSelf: 'center',
   width: "45%",
   minWidth: "300px"
  },

  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },

  inputLabel: {
    flex: 1,
    marginRight: 10, // Adjust the spacing between label and input
    fontSize: 16,
  },
  textInput: {
    fontSize: 16,
    // height: 30,
    padding: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.lightGray,
    backgroundColor: Colors.white,
    maxWidth:'300px',
    width:'100%',
    alignSelf: 'center',
    textAlign:'center'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  createButton: {
    flex: 1,
    backgroundColor: 'green',  
    borderRadius: 5,
    padding: 10,
    alignItems: 'center',
  },
  cancelButton: {
    flex: 1,
    backgroundColor: 'red',  
    borderRadius: 5,
    padding: 10,
    maxWidth: "160px",
    marginLeft: 10, 
    alignItems: 'center',
  },
  saveButton: {
    flex: 1,
    backgroundColor: '#112D4E', 
    borderRadius: 5,
    padding: 10,
    maxWidth: "160px",
    alignItems: 'center',
  },
  // buttonText: {
  //   color: 'white', // Change to your desired text color
  //   fontWeight: 'bold',
  //   fontSize: 16,
  // },


  addImagesButton: {
    backgroundColor:Colors.primary800, // Customize the button style
    borderRadius: 5,
    padding: 10,
    alignItems: 'center',
  },
  selectedImagesContainer: {
    marginTop: 20,
  },
  selectedImageContainer: {
    marginRight: 10,
    marginBottom: 10,
  },
  selectedImage: {
    width: 100,
    height: 100,
  },
  removeImageButton: {
    backgroundColor: 'red', // Customize the button style
    borderRadius: 5,
    padding: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  offerImage: {
    width: 100, // Adjust the width as needed
    height: 100, // Adjust the height as needed
    marginRight: 10, // Adjust the spacing between the image and other content
  },

  date: {
    flexDirection: 'column',
    color: Colors.mediumGray,
    marginBottom: 16, // Increase or decrease this margin as needed
  },
  kilometrage: {
    marginBottom: 16, // Increase or decrease this margin as needed
  },
  gearbox: {
    marginBottom: 16, // Increase or decrease this margin as needed
  },
  carburant: {
    marginBottom: 16, // Increase or decrease this margin as needed
  },
  description: {
    marginBottom: 16, // Increase or decrease this margin as needed
  },
  picker: {
    color: Colors.gray,
    marginBottom: 10,
    height: 33,
    width:'70%',
    alignSelf: 'center',
    textAlign:'center',
    flex: 2,
    borderColor: 'grey',   // Add border color
    borderWidth: 1,       // Add border width
    borderRadius: 5,
    padding: 8,
  },
  confirmButton: {
    flex: 1,
    backgroundColor: 'green', // Customize the button color
    borderRadius: 5,
    padding: 10,
    alignItems: 'center',
    maxWidth: '160px'
  },
  modalCreateOffer:{
    alignSelf: 'center',
    minWidth:"400px",
    width:"50%"
  },
  deleteModal:{
    alignSelf: 'center',
    minWidth:"300px",
    width:"30%"
  },
  label: {
    fontSize: 12,
    color: Colors.gray,
    marginBottom: 4,
    textAlign: 'center',
  },

  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#f5f5f5',
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  offerList: {
    marginBottom: 16,
    marginTop: 40,
    // minWidth: '300px',
    // padding: 16

  },
  offerCard: {
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    marginBottom: 18,
    padding: 12,
    width: "100%"
    

  },
  offerCard2: {
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
    paddingTop: 0,
    paddingLeft: 12,
    paddingRigh: 8,
    marginBottom: 16,
    borderRadius: 10,
    flex: 1,
  },

  offerText: {
    flexDirection: 'column',
  },
  imageContainer: {
    marginRight: 16,
  },
  offerImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  offerDetails: {
    flex: 2,
    flexDirection :"column",
    gap: '6px',
    minWidth: "230px",
    paddingLeft: 10,
    paddingRigh: 14,
    paddingTop: 10,

  },
  offerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  offerPrice: {
    fontSize: 16,
    padding: 6
  },

  offerDescription: {
    fontSize: 14,
    color: 'gray',
  },
  offerDate: {
    fontSize: 14,
    padding: 3,
  },
  deleteButton: {
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "20px",
    marginTop: '20px',
    minWidth: "90px"
  },
  deleteButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  editButton: {
    backgroundColor: '#112D4E', // Change the background color to your preference
    borderRadius: 5,
    padding: 10,
    alignItems: 'center',
    marginTop: '20px',
    marginLeft:'35px',
    minWidth: "90px"
  },
  editButtonText: {
    color: 'white', // Change the text color to your preference
    fontWeight: 'bold',
    fontSize: 16,
  },


  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },

  modalInner: {
    flexDirection: 'row', // Arrange children horizontally
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    flex: 1,
  },
  image: {
    width: 100,
    height: 100,
    marginRight: 10, // Adjust spacing as needed
  },
  textContainer: {
    flex: 2, // Adjust the flex ratio as needed
  },
  closeButton: {
    position: 'absolute',
    top: 10, // Adjust the top position as needed
    right: 10, // Adjust the right position as needed
    zIndex: 1, // Ensure the close button appears above the modal content
  },
  closeIcon: {
    fontSize: 20, // Adjust the font size as needed
    color: 'black', // Adjust the color as needed
  },
  dropListsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'center',
    marginTop: 10, 
   
  },  
  dropListCreateModal:{
    width: "50%",
  },
  // rowInputs:{
  //   width: "50%",
  // },

  dropInputsRow: {
    flexDirection: 'row',
    justifyContent: "space-around",
     alignItems:'center',
    marginBottom: 10,
    marginTop: 20, 
   
  },  

  dropInputsRowView:{
    width: '40%',
  },

  rowInput: {
    fontSize: 16,
    // height: 30,
     padding: 5,
    // marginBottom: 5,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.lightGray,
    backgroundColor: Colors.white,
    // maxWidth:'200px',
     width:'100%',
    alignSelf: 'center',
     textAlign:'center'
  },
  rowLabel:{
    fontSize: 12,
    color: Colors.gray,
    marginBottom: 4,
    textAlign: 'center',
  },
  header: {
    width: '100%',
    alignItems: 'center',
  },
  languagePicker: {
    marginBottom: '20px',
    marginRight: '20px',
    alignSelf:"flex-end"
  },
  offercolumns:{
    flexDirection: 'column',
    display: 'flex',    
  },
  offerLines:{
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  offerButtons: {
    // marginBottom: '20%',
    flexDirection: 'row',
    minWidth: "200px",

  },

  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },

  paginationButton: {
    padding: 10,
    margin: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ccc',
  },

  activePaginationButton: {
    backgroundColor: '#c3c3c3', // Adjust the color for the active page
    borderColor: '#c3c3c3',
  },

  paginationButtonText: {
    fontSize: 16,
    color: 'black', // Adjust the color for the page number text
  },
  
}
