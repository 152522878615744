import {  StyleSheet,Linking, Text, TouchableOpacity,TouchableWithoutFeedback, View,ScrollView } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { useContext, useEffect, useState } from 'react';
import Button from '../components/ui/Button';
import { Keyboard } from 'react-native';
import { style } from '../constants/CalculatorScreenStyle';
import Loader from '../components/ui/Loader';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useMediaQuery } from 'react-responsive'
import { useNavigation } from '@react-navigation/native';
import { NumericFormat } from 'react-number-format';
import {Alert} from 'react-native';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { LanguageContext } from '../store/context/language-context';
import { createStackNavigator } from '@react-navigation/stack';
import LegalMentionsScreen from '../screens/LegalMentionsScreen'; 
import MainNavigator from '../navigation.js';
import Link from 'react-router-dom';

function CalculatorScreen() {

  const [isCalculationAvailable, setIsCalculationAvailable] = useState(false);
  const [hasTaxCalculation, setHasTaxCalculation] = useState(false);
  const [hasResult, setResult] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [amountValue, setAmountValue] = useState('');
  const [transportFees, setSelectedTransportFees] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('Libre');
  const [selectedCarTypeList, setSelectedCarTypeList] = useState();
  const [selectedCarburant, setselectedCarburant] = useState('Neuf');
  const [date, setDate] = useState(new Date());
  // const authContext = useContext(AuthContext);
  const [selectedMotorisationEssence, setSelectedMotorisationEssence] = useState();
  const [selectedEnergyList, setSelectedEnergyList] = useState();
  const [totalValue, setTotalValue] = useState(0); 
  const [totalFees, setTotalFees] = useState(0); 
  const [selectedMotorisationDieselHybride, setSelectedMotorisationDieselHybride  ] = useState();
  const navigation = useNavigation();
  // const [language, setLanguage] = useState('fr');
 //const [language, setlanguage] = useState('ar');
  const [energyList, setEnergyList] = useState([]);
  const [methodsList, setMethodsList] = useState([]);
  const [motorisationEssenceList, setMotorisationEssenceList] = useState([]);
  const [motorisationDieselHybrideList, setMotorisationDieselHybrideList] = useState([]);
  const [carTypeList, setCarTypeList] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [dynamicMotorisationEssenceList, setDynamicMotorisationEssenceList] = useState( motorisationEssenceList);
  const [dynamicMotorisationDieselHybrideList, setDynamicMotorisationDieselHybrideList] = useState(motorisationDieselHybrideList);
  const [dynamicEnergyList, setDynamicEnergyList] = useState(energyList);
  const [exchangeRate, setExchangeRate] = useState('245');
  const { language, setLanguage, getLanguage } = useContext(LanguageContext);

  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1024px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })


  const importOptionsByLanguage = () => {
    switch (language) {
      case 'en':
        return import('../constants/en');
      case 'ar':
        return import('../constants/ar');
      default:
        return import('../constants/fr'); 
    }
  };  
  const Stack = createStackNavigator();

  const goToLegalMentions = () => {
    navigation.navigate('Legal'); 

    // navigation.navigate('MentionsLegales', {
    //   screen: 'MentionsLegales',
    // })
  };

  const translate = (key) => translations[key];

  const handleEnergyListChange = (itemValue, itemIndex) => {
    setSelectedEnergyList(itemValue);
  };


  const handleMethodChange = (selectedMethod) => {
    if (selectedMethod === '0') {
      // CCR method selected, set the motorisationDieselHybrideList to only the first choice
      setDynamicMotorisationDieselHybrideList([motorisationDieselHybrideList[0]]);
      setDynamicMotorisationEssenceList([motorisationEssenceList[0]]);
    } else {
      // Another method is selected, set the lists to the full list
      setDynamicMotorisationDieselHybrideList(motorisationDieselHybrideList);
      setDynamicMotorisationEssenceList(motorisationEssenceList);
    }

    if (selectedMethod === '0'|| selectedMethod === '1' ) {
      setDynamicEnergyList(energyList)
    } else {
      setDynamicEnergyList([energyList[0], energyList[2], energyList[4], energyList[5]]);

    }
  };



  useEffect(() => {
    
    const initializeLanguage = async () => {
      await getLanguage().then((storedLanguage) => {
        if (storedLanguage) {
          setLanguage(storedLanguage);
        }
      });
    };

    initializeLanguage();
    importOptionsByLanguage().then((options) => {
      setEnergyList(options.energyList);
      setMethodsList(options.methodsList);
      setMotorisationEssenceList(options.motorisationEssenceList);
      setMotorisationDieselHybrideList(options.motorisationDieselHybrideList);
      setCarTypeList(options.carTypeList);
      setTranslations(options.translations);

    });
    
    const isFormValid = (
      selectedCarTypeList &&
      selectedMethod && exchangeRate &&
      selectedEnergyList && (selectedMotorisationEssence  || selectedMotorisationDieselHybride) &&
       parseInt(amountValue)

    );


    if (isFormValid) {
      setIsCalculationAvailable(false);
      setHasTaxCalculation(false);
    } else {
      setIsCalculationAvailable(true);
    }
  }, [amountValue,language,transportFees,selectedCarTypeList,translations, selectedMethod ,selectedEnergyList ,selectedMotorisationEssence,selectedMotorisationDieselHybride]);

  const CalculateButton = ({ isDisable }) => {
    return (
      <View style={styles.footer}>
        <Button onPress={calculateTax} isMediumSize={true} isDisable={isDisable}>
        {translate('calculateButtonLabel')} 
        </Button>
      </View>
    );
  };

 
    const sendEmail = () => {
      Linking.openURL('mailto:cartaxe.dz@gmail.com');
    };
 

  const CalculationResult = () => {
    return (
      <>
        <View style={styles.result}>

        </View>
      </>
    );
  };

  const onChangeDate = (_, selectedDate) => {
    setDate(selectedDate);
  };

  const getDateInString = () => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  const handleLanguageChange = async (newlanguage) => {
    await setLanguage(newlanguage); 
  };
  
  
  const calculateTax = async () => {

    const functions = getFunctions();
    const calculateTaxFunction = httpsCallable(functions, 'calculateTax');


      const data = {
        id: Date.now(),
        value: amountValue,
        motorisationEssenceList: selectedMotorisationEssence,
        motorisationDieselHybrideList: selectedMotorisationDieselHybride,
        energyList : selectedEnergyList,
        methodsList: selectedMethod,
        carType: selectedCarTypeList,
        transportFees : transportFees,
        exchangeRate: exchangeRate,
      };

    setIsLoadingData(true);

try{
    const result = await calculateTaxFunction({simulationData:data})
      let totalValue = result.data.totalValueDAExt
      let totalFees = result.data.totalFees
      setTotalValue(totalValue);
      setTotalFees(totalFees)
      setResult(true)
      
    }
    catch(error) {
      alert("Error")
    }
    finally {
      setIsLoadingData(false);
    }
   
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <ScrollView contentContainerStyle={styles.rootContainer}>

      <View style={styles.header}> 
          <Picker
            selectedValue={language}
            onValueChange={handleLanguageChange}  
            style={styles.languagePicker}
          >
            <Picker.Item label="English" value="en" />
            <Picker.Item label="Français" value="fr" />
            <Picker.Item label="عربي" value="ar" />
          </Picker>
        </View>

        <View style={styles.header}>
          <Text style={styles.headerTitle}>{translate('headerTitle')}</Text>
          <Text style={styles.message}>{translate('message')}</Text>
        </View>

        

        {isDesktopOrLaptop &&  <View style={styles.body} >
      <View style={styles.gridContainer}>
        <View style={styles.rightColumn} >
                <View >
                      <Text style={styles.label}>{translate('totalValueHTLabel')}</Text>
                      <NumericFormat
                        style={styles.input}
                        value={amountValue}
                        allowLeadingZeros
                        thousandSeparator=","
                        onValueChange={(values, sourceInfo) => {
                          setAmountValue(values.value);}}
                          maxLength={7} />
                </View>

                <View >
                  <Text style={styles.label}>{translate('feesValueLabel')}</Text>

              <NumericFormat
                style={styles.input}
                value={transportFees}
                allowLeadingZeros
                thousandSeparator=","
                onValueChange={(values, sourceInfo) => {
                  setSelectedTransportFees(values.value);}} 
                  maxLength={5}/>
                </View>


       <View>
              <Text style={styles.label}>{translate('exchangeRateLabel')}</Text>
              <NumericFormat
                style={styles.input}
                value={exchangeRate}
                onValueChange={(values, sourceInfo) => {
                  setExchangeRate(values.value);}} 
                  maxLength={3}
                placeholder={translate('exchangeRatePlaceholder')}
                keyboardtype="numeric"
              />
        </View>
        

        <View>
              <Text style={styles.labelOfficialRate}>{translate('exchangeRateLabelOfficial')}</Text>
        </View>

                <View >
                  <Text style={styles.label}>{translate('vehicleTypeLabel')}</Text>
                  <Picker style={styles.picker}
                    selectedValue={selectedCarTypeList}
                    onValueChange={(itemValue, itemIndex) => setSelectedCarTypeList(itemValue)}
                  >
                    <Picker.Item label="" value="" />
                    {carTypeList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>


                <View >
                  <Text style={styles.label}>{translate('detaxWayLabel')}</Text>
                  <Picker style={styles.picker}
                    selectedValue={selectedMethod}
                    onValueChange={(itemValue, itemIndex) => {
                      setSelectedMethod(itemValue);
                      handleMethodChange(itemValue);
                    }}
                  >
                    <Picker.Item label="" value="" />
                    {methodsList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>
                
          <View > 
            <Text style={styles.label}> {translate('fuelTypeLabel')}</Text>
            <Picker style={styles.picker}
              selectedValue={selectedEnergyList}
              onValueChange={handleEnergyListChange}
            >
              <Picker.Item label="" value="" />
              {dynamicEnergyList.map((item, index) => (
                <Picker.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Picker>
          </View>

             {selectedEnergyList === '0' || selectedEnergyList === '2' ? (
                <View >
                  <Text style={styles.label}>{translate('engineTypeLabel')} </Text>
                  <Picker
                    style={styles.picker}
                    selectedValue={selectedMotorisationEssence}
                    onValueChange={(itemValue, itemIndex) => setSelectedMotorisationEssence(itemValue)}
                  >
             <Picker.Item label="" value="" />
              {dynamicMotorisationEssenceList.map((item, index) => (
                <Picker.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Picker>
                </View>
              ) : null}


          {selectedEnergyList === '1' || selectedEnergyList === '3' ? (
                <View >
                  <Text style={styles.label}>{translate('engineTypeLabel')} </Text>
                  <Picker
                    style={styles.picker}
                    selectedValue={selectedMotorisationDieselHybride}
                    onValueChange={(itemValue, itemIndex) => setSelectedMotorisationDieselHybride(itemValue)}
                  >
                    <Picker.Item label="" value="" />
                    {motorisationDieselHybrideList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>
              ) : null}
      
  
        </View>
       

        <View style={styles.leftColumn}>
      <View style={styles.resultInput}>
          <Text style={styles.label}>{translate('totalValueLabel')}</Text>
          <NumericFormat
                      style={styles.inputResult}
                      value={totalValue}
                      allowLeadingZeros
                      thousandSeparator=","
                      disabled={true}
                    />
      </View> 
      <View style={styles.resultInput}  >
          <Text style={styles.label}>{translate('totalFeesLabel')}</Text>
          <NumericFormat
                      style={styles.inputResult}
                      value={totalFees}
                      allowLeadingZeros
                      thousandSeparator=","
                      disabled={true}
                    />

      </View>
</View>
      </View>


      {hasTaxCalculation ? (
          !!currencyRate ? (
            <CalculationResult />
          ) : null
        ) : isLoadingData ? (
          <Loader />
        ) : (
          <CalculateButton isDisable={isCalculationAvailable} />
        )}
          </View>}



    {isTabletOrMobile &&  <View style={styles.body2} >
      <View style={styles.gridContainer}>
        <View style={styles.rightColumn2} >
                <View >
                  <Text style={styles.label}>{translate('totalValueHTLabel')}</Text>
  
                    <NumericFormat
                      style={styles.input2}
                      value={amountValue}
                      allowLeadingZeros
                      thousandSeparator=","
                      maxLength={7}
                      onValueChange={(values, sourceInfo) => {
                        setAmountValue(values.value);
                      }}
                    />

                </View>

                <View >
                  <Text style={styles.label}>{translate('feesValueLabel')}</Text>

              <NumericFormat
                style={styles.input2}
                value={transportFees}
                allowLeadingZeros
                thousandSeparator=","
                maxLength={5}
                onValueChange={(values, sourceInfo) => {
                  setSelectedTransportFees(values.value);}} />
                </View>

                <View>
             <Text style={styles.label}>{translate('exchangeRateLabel')}</Text>
              <NumericFormat
                style={styles.input2}
                value={exchangeRate}
                onValueChange={(values, sourceInfo) => {
                  setExchangeRate(values.value);}} 
                  maxLength={3}
                // placeholder={translate('exchangeRatePlaceholder')}
                keyboardtype="numeric"
              />
        </View>


        <View>
              <Text style={styles.labelOfficialRate}>{translate('exchangeRateLabelOfficial')}</Text>
        </View>

                <View >
                  <Text style={styles.label}>{translate('vehicleTypeLabel')}</Text>
                  <Picker style={styles.picker2}
                    selectedValue={selectedCarTypeList}
                    onValueChange={(itemValue, itemIndex) => setSelectedCarTypeList(itemValue)}
                  >
                    <Picker.Item label="" value="" />
                    {carTypeList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>


                <View >
                  <Text style={styles.label}>{translate('detaxWayLabel')}</Text>
                  <Picker style={styles.picker2}
                    selectedValue={selectedMethod}
                    onValueChange={(itemValue, itemIndex) => {
                      setSelectedMethod(itemValue);
                      handleMethodChange(itemValue);
                    }}
                  >
                    <Picker.Item label="" value="" />
                    {methodsList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>

          <View >
            <Text style={styles.label}>{translate('fuelTypeLabel')} </Text>
            <Picker style={styles.picker2}
              selectedValue={selectedEnergyList}
              onValueChange={handleEnergyListChange}
            >
              <Picker.Item label="" value="" />
              {dynamicEnergyList.map((item, index) => (
                <Picker.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Picker>
          </View>

             {/* Conditional rendering based on the selected "Type du véhicule" */}
             {selectedEnergyList === '0' || selectedEnergyList === '2' ? (
                <View >
                  <Text style={styles.label}>{translate('engineTypeLabel')}  </Text>
                  <Picker
                    style={styles.picker2}
                    selectedValue={selectedMotorisationEssence}
                    onValueChange={(itemValue, itemIndex) => setSelectedMotorisationEssence(itemValue)}
                  >
                    <Picker.Item label="" value="" />
                    {dynamicMotorisationEssenceList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>
              ) : null}


          {selectedEnergyList === '1' || selectedEnergyList === '3' ? (
                <View >
                  <Text style={styles.label}>{translate('engineTypeLabel')} </Text>
                  <Picker
                    style={styles.picker2}
                    selectedValue={selectedMotorisationDieselHybride}
                    onValueChange={(itemValue, itemIndex) => setSelectedMotorisationDieselHybride(itemValue)}
                  >
                    <Picker.Item label="" value="" />
                    {dynamicMotorisationDieselHybrideList.map((item, index) => (
                      <Picker.Item key={item.value} label={item.label} value={item.value} />
                    ))}
                  </Picker>
                </View>
              ) : null}
      
  


      <View style={styles.leftColumn2}>
    
{hasResult && (
<View >      
       <View >
          <Text style={styles.label}>{translate('totalValueLabel')}</Text>
          <NumericFormat
            style={styles.inputResult2}
            value={totalValue}
            allowLeadingZeros
            thousandSeparator=","
            disabled={true}
          />
        </View>

        <View>
          <Text style={styles.label}>{translate('totalFeesLabel')}</Text>
          <NumericFormat
                      style={styles.inputResult2}
                      value={totalFees}
                      allowLeadingZeros
                      thousandSeparator=","
                      disabled={true}
                    />
       </View>    
    </View>)}
       

              </View>
          </View>

      </View>



      {hasTaxCalculation ? (
          !!currencyRate ? (
            <CalculationResult />
          ) : null
        ) : isLoadingData ? (
          <Loader />
        ) : (
          <CalculateButton isDisable={isCalculationAvailable} />
        )}
    </View>}


    {isDesktopOrLaptop && <View style={styles.contactLegalContainer2}>
          <View>
            <TouchableOpacity onPress={sendEmail}>
              <Text style={styles.contactText}>{translate('contactText')}</Text>
            </TouchableOpacity>
          </View>

          <View >
            <TouchableOpacity onPress={goToLegalMentions}>
              <Text style={styles.contactText}>{translate('legalText')}</Text>
            </TouchableOpacity>
          </View>
</View>}

{isTabletOrMobile && <View style={styles.contactLegalContainer}>
          <View>
            <TouchableOpacity onPress={sendEmail}>
              <Text style={styles.contactText}>{translate('contactText')}</Text>
            </TouchableOpacity>
          </View>

          <View >
          {/* <a href="LoginScreen"> coucou </a> */}
            <TouchableOpacity onPress={goToLegalMentions}>
              <Text style={styles.contactText}>{translate('legalText')}</Text>
            </TouchableOpacity>
          </View>
</View>}
        
      </ScrollView>
    </TouchableWithoutFeedback>
  );
}

export default CalculatorScreen;

const styles = StyleSheet.create(style);
