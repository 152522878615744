// Navigation.js

import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import CalculatorScreen from './screens/CalculatorScreen'; // Import your screen components
import LegalMentionsScreen from './screens/LegalMentionsScreen'; // Import the 'Mentions légales' screen
import LoginScreen from './screens/LoginScreen'; 
import SignupScreen from './screens/SignupScreen'; 


const Stack = createStackNavigator();

const MainNavigator = () => (
  <Stack.Navigator>
    <Stack.Screen name="Calculatrice" component={CalculatorScreen} />
    <Stack.Screen name="Legal" component={LegalMentionsScreen} />
    {/* <Stack.Screen name="LoginScreen" component={LoginScreen} />
    <Stack.Screen name="SignupScreen" component={SignupScreen} /> */}
  </Stack.Navigator>
);


export default MainNavigator;
